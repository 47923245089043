(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("coreUtilsLib"), require("color"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "coreUtilsLib", "color"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("lodash"), require("coreUtilsLib"), require("color")) : factory(root["lodash"], root["coreUtilsLib"], root["color"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__6__) {
return 